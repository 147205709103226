.swiper.PhotoAlbumSliderBox {
	width: 100%;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1280px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.PhotoAlbumSliderBox .swiper-slide {
	text-align: center;
	font-size: 18px;
	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
}

.PhotoAlbumSliderBox .swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.swiper.PhotoAlbumSliderBox .swiper-pagination-bullet {
	background-color: var(--color_01);
}

.swiper .swiper-button-prev::after {
	content: "next";
}

.swiper .swiper-button-next::after {
	content: "prev";
}

.photo_album_slider_bg_animation {
	position: relative;
}

.photo_album_slider_bg_animation::before {
	left: 0;
	border-top-left-radius: 6px;
}
.photo_album_slider_bg_animation::after {
	right: 0;
	border-top-right-radius: 6px;
}
.photo_album_slider_bg_animation::after,
.photo_album_slider_bg_animation::before {
	content: "";
	position: absolute;
	top: 0;
	height: 100%;
	width: 0;

	background: var(--color_04);

	transition: 0.3s ease-in-out;
	-webkit-transition: 0.3s ease-in-out;
	-moz-transition: 0.3s ease-in-out;
	-ms-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
}
.photo_album_slider_bg_animation:hover::after,
.photo_album_slider_bg_animation:hover::before {
	content: "";
	width: 50%;
	height: 100%;
}
