@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--color_01: #283078;
	--color_02: #283078;
	--color_03: #658eca;
	--color_04: #658dca67;
	--color_05: #edfdfb;
	--color_06: #fff;
	--color_07: #636161;
	--color_08: #212529;
	--color_09: #;
	--color_10: #f6f6f6;
}
* {
	font-family: "Tajawal", sans-serif !important;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
	display: inline-block;
}
html {
	scroll-behavior: smooth;
}
body {
	max-width: 100%;
	overflow-x: hidden;
}

/* Target the scrollbar track */
::-webkit-scrollbar {
	width: 6px;
}

/* Handle for the scrollbar */
::-webkit-scrollbar-thumb {
	background-color: var(--color_02);
	border-radius: 6px;
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	-ms-border-radius: 6px;
	-o-border-radius: 6px;
}

/* Highlight on hover */
::-webkit-scrollbar-thumb:hover {
	background-color: var(--color_03);
}

/* Track (the area on which the handle runs) */
::-webkit-scrollbar-track {
	background-color: none;
}

/* Handle on the top and bottom */
::-webkit-scrollbar-button {
	display: none;
}
.activeLink .MuiListItem-root {
	background-color: #283078;
	color: #ffffff;
}

.global_Padding {
	padding-top: 30px;
	padding-bottom: 50px;
}

.swiper .swiper-button-prev::after {
	content: "next";
	margin-right: 0 !important;
}

.swiper .swiper-button-next::after {
	content: "prev";
	margin-right: 0 !important;
}
.swiper .swiper-button-prev,
.swiper .swiper-button-next {
	content: "";
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: var(--color_06);
}
.swiper .swiper-button-prev:hover,
.swiper .swiper-button-next:hover {
	content: "";
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: var(--color_03);
}

.swiper .swiper-button-prev::after,
.swiper .swiper-button-next::after {
	content: "";
	font-family: swiper-icons;
	font-size: 22px;
	font-weight: bold;
	margin-right: -3px;
	color: var(--color_02);

	transition: var(--main-transition-01);
}
.swiper .swiper-button-next {
	/* margin-right: 25px !important; */
	left: 1px !important;
}
.swiper .swiper-button-prev {
	/* margin-right: -25px !important; */
	right: 1px !important;
}
.swiper .swiper-button-prev::after {
	content: "";
	margin-right: -3px !important;
}
.swiper .swiper-button-next::after {
	content: "";

	margin-right: 3px !important;
}
.global_before_animation {
	position: relative;
}

.global_before_animation::before {
	content: "";
	position: absolute;
	bottom: 0;
	height: 0%;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	background: var(--color_03);
	border-bottom-right-radius: 6px;
	border-bottom-left-radius: 6px;
	transition: 0.3s ease-in-out;
	-webkit-transition: 0.3s ease-in-out;
	-moz-transition: 0.3s ease-in-out;
	-ms-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
}
.global_before_animation:hover::before {
	content: "";
	width: 100%;
	height: 50%;
}
.PhotoAlbumSliderBox .global_before_animation::after {
	content: "";
	background: var(--color_03);
}
.PhotoAlbumSliderBox .global_before_animation::before {
	content: "";
	background: var(--color_03);
}
.global_before_animation::after {
	content: "";
	position: absolute;
	top: 0;
	height: 0%;
	width: 100%;
	left: 50%;
	transform: translateX(-50%);
	background: var(--color_03);
	border-top-right-radius: 6px;
	border-top-left-radius: 6px;
	transition: 0.3s ease-in-out;
	-webkit-transition: 0.3s ease-in-out;
	-moz-transition: 0.3s ease-in-out;
	-ms-transition: 0.3s ease-in-out;
	-o-transition: 0.3s ease-in-out;
}
.global_before_animation:hover::after {
	content: "";
	width: 100%;
	height: 50%;
}

.global_shadow {
	transition: 0.4s;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}
.global_shadow:hover {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;
}
@media (min-width: 1536px) {
	.container {
		max-width: 1536px;
	}
}
@media (min-width: 1980px) {
	.container {
		max-width: 1980px;
	}
}
/* .link_footer li a svg{

width: 34px; height: 34px;

} */

.nav_link_max {
	position: relative;
}

.nav_link_max.active,
.nav_link_max:hover {
	background-color: var(--color_03);
	color: var(--color_06);
}
.DropDownBox .icon_rotate {
	transform: rotate(0deg);
	transition: 0.3s;
	-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-ms-transition: 0.3s;
	-o-transition: 0.3s;
}
.DropDownBox.active .icon_rotate {
	transform: rotate(180deg);
	color: var(--color_06) !important;
}
.DropDownBox {
	transition: 0.2s;
}
.DropDownBox.active {
	background-color: var(--color_03);
	color: var(--color_06);
}

/* loader style */
.loading-logo {
	animation: logoAnimation 1.7s ease-in-out infinite;
	-webkit-animation: logoAnimation 1.7.7s ease-in-out infinite;
}

/* Define the keyframes */
@keyframes logoAnimation {
	0% {
		opacity: 1;
		transform: scale(1);
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
	}
	50% {
		opacity: 0.6;
		transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-moz-transform: scale(1.1);
		-ms-transform: scale(1.1);
		-o-transform: scale(1.1);
	}
	100% {
		opacity: 1;
		transform: scale(1);
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
	}
}

/** file_name */

.file_name {
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	height: 100%;
	overflow: hidden;
	width: 100%;
	border-bottom: 1px solid #658eca1a;
}

.line-clamp-text {
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	height: 100%;
	overflow: hidden;
	width: 100%;
	text-align: center;
}

.one-line-clamp-text {
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	height: 100%;
	overflow: hidden;
	width: 100%;
}
.two-line-clamp-text {
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	height: 100%;
	overflow: hidden;
	width: 100%;
}
